import * as React from 'react';

import * as Popover from '@radix-ui/react-popover';

import { Button } from 'components/common-n4/button';
import SvgIcon from 'components/ui/svg_icon';
import { tw, validatePostalCode } from 'lib/utils';

import styles from './start.module.scss';

import { ContentProps, ErrorCode, Step, SystemAction, UserAction, type Distance } from '../types';

const distanceOptions = [
  { label: '25 Miles', value: 25 },
  { label: '50 Miles', value: 50 },
  { label: '75 Miles', value: 75 },
  { label: '100 Miles', value: 100 },
  { label: '150 Miles', value: 150 },
];

interface HTMLInputElementWithVK extends HTMLInputElement {
  virtualKeyboardPolicy: 'auto' | 'manual' | '';
}

export const Start: React.FC<ContentProps> = ({ dispatch, state }) => {
  const [shouldOpenPopover, setShouldOpenPopover] = React.useState(false);

  const zipCodeInputRef = React.useRef<HTMLInputElementWithVK>(null);

  React.useEffect(() => {
    if ('virtualKeyboard' in navigator) {
      zipCodeInputRef.current!.virtualKeyboardPolicy = 'manual';
    }
    zipCodeInputRef.current?.focus();
    if ('virtualKeyboard' in navigator) {
      zipCodeInputRef.current!.virtualKeyboardPolicy = 'auto';
    }
  }, []);

  const handleSubmit = () => {
    if (!state.postalCode) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.user_error_no_zip, message: 'Please provide your zip code', field: 'postalCode' } },
      });
    } else if (!validatePostalCode(state.postalCode)) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.user_error_bad_zip, message: 'Zip code must be 5 digits', field: 'postalCode' } },
      });
    } else {
      dispatch({ type: UserAction.submit });
    }
  };

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: postalCode } = event.target;
    /*
    let postalCode = value.replace(/\D/g, '');
    const zipCoderSize = 5;

    if (postalCode.length > zipCoderSize) {
      postalCode = postalCode.substring(0, zipCoderSize);
    }
    */
    dispatch({ type: UserAction.change, payload: { postalCode } });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  };

  const handleSelectDistance = (distance: Distance) => {
    const submitButton = document.getElementById('submit-button');
    setShouldOpenPopover(() => {
      dispatch({ type: UserAction.change, payload: { distance } });
      return false;
    });
    submitButton?.focus();
  };

  return (
    <section className={tw('flex w-full items-center', styles['pop-up'])}>
      <figure className={styles['pop-up-image']} />
      <div className={tw('flex w-full items-center md:items-start', styles['pop-up-content'])}>
        <div className={tw(styles['pop-up-logo-container'])}>
          <div className={tw('flex w-full items-center justify-center', styles['pop-up-logo'])} />
        </div>
        <div className={tw('flex items-center justify-center', styles['pop-up-title'])}>
          <span className={'text-center font-semibold text-navy'}>First, let’s make sure there’s an affiliated provider near you.</span>
        </div>

        <div className={tw('text-center text-base font-normal text-navy', styles['pop-up-sub-title'])}>
          <i>HearingTracker</i> visitors save up to 35% off retail prices, without sacrificing local care.
        </div>

        <div className={tw(styles['pop-up-content-zip-code'])}>
          {state.error && (
            <div className="mb-[10px] ml-[18px] text-sm" style={{ color: '#c00' }}>
              {state.error.message}
            </div>
          )}
          <input
            className={tw(state.error?.field === 'postalCode' ? styles.error : '', 'remove-arrow')}
            type="text"
            placeholder="Enter ZIP Code"
            onKeyDown={handleKeyDown}
            onChange={handleZipCodeChange}
            value={state.postalCode}
            ref={zipCodeInputRef}
          />
        </div>

        <div className={styles['pop-up-content-dropdown']}>
          <label htmlFor="options" className={tw('text-center font-normal text-navy-95', styles['pop-up-content-dropdown-label'])}>
            Set distance to:
          </label>
          <Popover.Root open={shouldOpenPopover} onOpenChange={setShouldOpenPopover} defaultOpen={false}>
            <Popover.Trigger asChild>
              <button
                type="button"
                className={tw(
                  'flex h-10 min-w-[108px] max-w-[108px] items-center justify-between',
                  'rounded-[10px] border border-[#14254633] bg-white px-3 py-2 align-middle'
                )}
              >
                <div className="font-smooth whitespace-nowrap text-[14px] font-medium leading-tight text-navy">{state.distance} Miles</div>
                <SvgIcon
                  icon="chevron-down-solid"
                  aria-hidden
                  className="relative mb-[1px] h-[14px] w-[14px]"
                  style={{ filter: 'invert(14%) sepia(41%) saturate(941%) hue-rotate(182deg) brightness(91%) contrast(75%)' }}
                />
              </button>
            </Popover.Trigger>
            <Popover.Content side="top" align="start" sideOffset={9} className="z-50">
              <ul className="flex w-[175px] flex-col items-start justify-end rounded-xl border border-[#14254633] bg-white p-2 shadow">
                {distanceOptions.map((dist) => (
                  <li key={dist.label} className="flex w-[159px] rounded-[10px] px-4 py-2 hover:bg-navy-5 active:bg-navy-5">
                    <button
                      type="button"
                      onClick={() => handleSelectDistance(dist.value as Distance)}
                      className="flex h-full w-full justify-start align-middle"
                    >
                      <span className="font-smooth text-sm font-medium leading-tight text-navy">{dist.label}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </Popover.Content>
          </Popover.Root>
        </div>
        <div className="flex w-full justify-center">
          <Button.Primary
            id={'submit-button'}
            className={tw('flex items-center justify-center', styles['pop-up-content-button'])}
            onClick={handleSubmit}
            buttonType="button"
            isDisabled={state.step === Step.searching}
          >
            {state.step === Step.searching ? 'Checking...' : 'Check my location'}
          </Button.Primary>
        </div>
      </div>
    </section>
  );
};
