import React from 'react';

import { usePathname } from 'next/navigation';

import { Button } from 'components/common-n4/button';
import { useOncePerPage } from 'hooks';
import { getAb, recordAbEvent } from 'lib/ab-tests';
import Logger from 'lib/logger';
import { normalizeUrl } from 'lib/utils';
import type { Release, Model, HearingAid, Price } from 'types/release';

import { ProviderFlow } from './provider-pop-up';
import { Event } from './provider-pop-up/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = Logger({ category: 'price-button' });
/*
                    R
          TL1                TL2
  HA1(FF1) HA2(FF2)  HA3(FF1)  HA4 (FF3)

  For the purpose of showing the popup:
    if the card is showing a hearing aid:
      HA1 is a go if we have HA1 or TL1
      HA2 is a go if we have HA2 or TL1
      HA3 is a go if we have HA3 or TL2
      HA4 is a go if we have HA4 or TL2

    if the card is showing a form factor (model):
      FF3 is a go if we have TL1 or TL2
      FF2 is only if we have TL1
      FF3 is only if we have TL2

  For the purpose of showing the starting price:
    All of the above applies, the only difference
    is the ha price is used if there is one.

*/

interface PriceButtonProps {
  release: Release;
  model?: Model;
  hearingAid?: HearingAid;
  price?: Price;
  segmentOrigin: string;
  className?: string;
  onModalOpenChange?: (isOpening: boolean) => void;
  noGeo: boolean;
  origin: string;
  position: string;
}

const PriceButton: React.FC<PriceButtonProps> = ({ release, model, hearingAid, price, className, onModalOpenChange, origin, position, segmentOrigin }) => {
  const [isProviderShowing, showProvider] = React.useState<boolean>(false);
  const [segment, setSegment] = React.useState(-2);
  const useOnce = useOncePerPage();
  const pathname = usePathname();
  const [buttonState, setButtonState] = React.useState({ text: 'Loading...', link: '', disabled: true, shouldOpenTab: false, cta: '', seller: '' });

  if (useOnce({ item: segmentOrigin, skip: segment < 0 })) {
    const testName = `${segmentOrigin}${price?.partner_priority ? 'Partner' : 'Affiliate'}`;
    recordAbEvent({
      event: Event.ButtonViewed,
      testName,
      segment,
      origin,
      properties: { category: 'price-button', position, release: release.slug, seller: price?.seller || '' },
    });
  }

  React.useEffect(() => {
    const onReleasePage = pathname === release.path;
    if (!price) {
      if (onReleasePage) {
        setButtonState({ text: 'No Offers', link: '', disabled: true, shouldOpenTab: false, cta: '', seller: '' });
      } else {
        if (!release.path) {
          setButtonState({
            text: 'Archived Product',
            link: '',
            disabled: true,
            shouldOpenTab: false,
            cta: '',
            seller: '',
          });
        } else {
          setButtonState({
            text: 'Product Info',
            link: normalizeUrl({ url: release.path, origin: 'price-button' }),
            disabled: false,
            shouldOpenTab: false,
            cta: 'Product Info',
            seller: '',
          });
        }
      }
    } else if (price.url) {
      const abName = `${segmentOrigin}${price.partner_priority ? 'Partner' : 'Affiliate'}`;
      const { cta, text, segment: seg } = getAb(abName, { release: release.name });
      setSegment(seg);
      setButtonState({ text, link: price.url, disabled: false, shouldOpenTab: true, cta, seller: price.seller });
    } else {
      if (onReleasePage) {
        // Maybe this should be a anchor to model comparison section?
        setButtonState({ text: 'No Current Offers', link: '', disabled: true, shouldOpenTab: false, cta: '', seller: price.seller });
      } else {
        if (!release.path) {
          setButtonState({
            text: 'Archived Product',
            link: '',
            disabled: true,
            shouldOpenTab: false,
            cta: '',
            seller: '',
          });
        } else {
          setButtonState({
            text: 'View Available Prices',
            link: normalizeUrl({ url: release.path, origin: 'price-button' }),
            disabled: false,
            shouldOpenTab: false,
            cta: 'View Available Prices',
            seller: price.seller,
          });
        }
      }
    }
  }, [price, pathname, release.path, release.name, segmentOrigin]);

  /*
  React.useEffect(() => {
    if (typeof window.localStorage !== 'undefined') {
      let seg: number | null = null;
      const segProp = `pf-segment-${segmentOrigin}`;
      if (localStorage.getItem(segProp)) {
        seg = parseInt(localStorage.getItem(segProp)!, 10);
        const ctaProp = 
        if (seg < 0 || seg > CTASegments[segmentOrigin].length - 1) {
          seg = null;
        }
      }
      if (seg === null) {
        seg = Math.floor(Math.random() * CTASegments[segmentOrigin].length);
        window.localStorage.setItem('pf-segment', seg.toString());
      }
      setSegment(seg);
    } else {
      setSegment(-1);
    }
  }, [segmentOrigin, price]);
  */

  /*
  React.useEffect(() => {
    if (price || noGeo) {
      // If we pass in price, assume we don't need to get a geo-coded price
      return;
    }
    const fn = async () => {
      const result = await fetchApi({
        path: `releases/${release.slug}/price`,
        variables: {
          modelId: model?.id,
          hearingAidId: hearingAid?.id,
          origin: `widgets/price-button.js[${origin}]`,
        },
        fallback: 'nope',
        log,
        origin: `widgets/price-button.js[${origin}]`,
      });
      setGeoPrice({ loading: false, price: result === 'nope' ? undefined : result });
    };
    fn();
  }, [release.slug, model?.id, hearingAid?.id, price, noGeo, origin]);
  */

  const handleSearch = () => {
    showProvider(true);
    if (onModalOpenChange) {
      onModalOpenChange(true);
    }
  };

  const onClose = () => {
    showProvider(false);
    if (onModalOpenChange) {
      onModalOpenChange(false);
    }
  };

  if ('one'.length === 0 && price?.provider_search) {
    let cta;
    if (segment === -2) {
      cta = 'Loading...';
    } else if (segment === -1) {
      cta = 'Find Care';
    } else if (segment >= 0) {
      cta = getAb(segmentOrigin, { release: release.name })?.cta;
    } else {
      console.warn('Segment is unknown %d', segment);
      cta = 'Find Care';
    }
    // display popup
    return (
      <>
        <ProviderFlow
          release={release}
          model={model}
          hearingAid={hearingAid}
          onClose={onClose}
          isModalOpen={isProviderShowing}
          origin={origin}
          segment={segment}
          segmentOrigin={segmentOrigin}
          position={position}
        />
        <Button.Primary className={className || '!w-full'} onClick={handleSearch} isFull>
          {cta}
        </Button.Primary>
      </>
    );
  }

  if (buttonState.link) {
    return (
      <Button.LinkPrimary
        className={className || '!w-full'}
        href={buttonState.link}
        shouldOpenNewTab={buttonState.shouldOpenTab}
        loader="false"
        data-track={!!buttonState.cta}
        data-event-name="Button"
        data-event-position={position}
        data-event-product={release.slug}
        data-event-cta={buttonState.cta}
        data-event-seller={buttonState.seller}
        data-event-url={buttonState.link}
      >
        {buttonState.text}
      </Button.LinkPrimary>
    );
  }

  return (
    <Button.Primary className={className || '!w-full'} isFull isDisabled={buttonState.disabled}>
      {buttonState.text}
    </Button.Primary>
  );
};

export default PriceButton;
