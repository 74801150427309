import * as React from 'react';

import { Button } from 'components/common-n4/button';
import { Link } from 'components/common-n4/link';
import TrustpilotStars from 'images/trustpilot-stars.svg';
import { tw } from 'lib/utils';

import styles from '../shared.module.scss';

import { ContentProps } from '../types';

export const Submitted: React.FC<ContentProps> = ({ state, handleClose }) => {
  const response = state.submitResponse!;
  return (
    <section className={tw('flex w-full items-center', styles['pop-up'], styles['found-pop-up'])}>
      <figure className={styles['pop-up-image']} />
      <div className={tw('flex w-full items-center md:items-start', styles['pop-up-content'])}>
        <div className={tw(styles['pop-up-logo-container'])}>
          <div className={tw('flex w-full items-center justify-center', styles['pop-up-logo'])} />
        </div>

        <div className={tw('flex items-center justify-center', styles['pop-up-title'], styles['provider-pop-up-title'])}>
          <span className={'text-center font-semibold text-navy'}>{response.message}</span>
        </div>

        <div className={tw('text-center text-base font-normal text-navy', styles['pop-up-sub-title'], styles['provider-pop-up-sub-title'])}>
          {response.caption}
        </div>
        <div className={tw('flex h-[86px] w-full items-center justify-between p-4', styles['pop-up-provider'])}>
          <div className={tw('flex h-full w-full flex-col justify-center', styles['provider-details'])}>
            <span className={styles.title}>{response.provider}</span>
            <span className={styles['partner-name']}>{response.disclaimer}</span>
            {response.trustPilotLink && response.trustPilotText && (
              <Link variant="primary" href={response.trustPilotLink}>
                {response.trustPilotText}
              </Link>
            )}
          </div>
          <div>
            <TrustpilotStars />
          </div>
        </div>

        <Button.Primary
          className={tw(
            'mt-[21px] flex max-w-[390px] flex-col items-center justify-center xs:mt-[20px] md:mt-[18px] md:max-w-full',
            styles['pop-up-content-button'],
            styles['provider-info-button']
          )}
          buttonType="submit"
          onClick={handleClose}
        >
          {response.buttonText || 'OK'}
        </Button.Primary>
      </div>
    </section>
  );
};
