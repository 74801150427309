// Probably needs to be deleted
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';
import { cx } from 'lib/utils';

// Can share from container if there are not many styles, otherwise use separate module per component
import styles from './home-container.module.scss';

const log = logger({ category: 'n4/home/hero' });

const HearingAidTabs = ({ blok, story, ...props }) => {
  log.debug('blok: %o', blok);
  return (
    <section className={cx(styles['hearing-aid-tabs'], blok.className)} {...storyblokEditable(blok)} style={{ border: '1px solid green', margin: 20 }}>
      <h2>Hearing Aid Tabs/Categories</h2>
      <ul>
        <li>title: {blok.title}</li>
      </ul>
      {blok.categories.length
        ? blok.categories.map((c) => <StoryblokComponent blok={c} story={story} {...props} key={c._uid} {...storyblokEditable(c)} />)
        : null}
    </section>
  );
};

export default HearingAidTabs;
