import Article from './article';
import GenericCta from './generic-cta';
import HearingAid from './hearing-aid';
import HearingAidTab from './hearing-aid-tab';
import HearingAidTabs from './hearing-aid-tabs';
import HearingAids from './hearing-aids';
import Hero from './hero';
import HeroCarouselItem from './hero-carousel-item';
import HeroLower from './hero-lower';
import HeroLowerCard from './hero-lower-card';
import HeroReviews from './hero-reviews';
import HomeContainer from './home-container';
import MultiCta from './multi-cta';
import MultiCtaCard from './multi-cta-card';
import Testimonial from './testimonial';
import Testimonials from './testimonials';
import HomeVideo from './video';

const homeComponents = {
  'home-article': Article,
  'home-container': HomeContainer,
  'home-generic-cta': GenericCta,
  'home-hearing-aid': HearingAid, // probably obsolete
  'home-hearing-aids': HearingAids, // this includes the tabs
  'home-hearing-aid-tab': HearingAidTab, // probably obsolete
  'home-hearing-aid-tabs': HearingAidTabs, // probably obsolete
  'home-hero': Hero,
  'home-hero-lower': HeroLower,
  'home-hero-lower-card': HeroLowerCard,
  'home-hero-reviews': HeroReviews,
  'home-hero-carousel-item': HeroCarouselItem,
  'home-multi-cta': MultiCta,
  'home-multi-cta-card': MultiCtaCard,
  'home-testimonial': Testimonial,
  'home-testimonials': Testimonials,
  'home-video': HomeVideo,
};

export default homeComponents;
