import React from 'react';

import { usePathname } from 'next/navigation';

import { Button } from 'components/common-n4/button';
import { Link } from 'components/common-n4/link';
import { useOncePerPage } from 'hooks';
import { getAb, recordAbEvent } from 'lib/ab-tests';
import Logger from 'lib/logger';
import { formatCurrency, normalizeUrl } from 'lib/utils';
import type { Release, Model, HearingAid, Price } from 'types/release';

import Currency from './currency';
import { ProviderFlow } from './provider-pop-up';
import { Event } from './provider-pop-up/types';

const log = Logger({ category: 'price-link' });

interface PriceLinkProps {
  release: Release;
  model?: Model;
  hearingAid?: HearingAid;
  price?: Price;
  className?: string;
  onModalOpenChange?: (isOpening: boolean) => void;
  origin: string;
  position: string;
  linkPrefix?: string;
}

const segmentOrigin = 'link';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PriceLink: React.FC<PriceLinkProps> = ({ release, model, hearingAid, price, className, onModalOpenChange, origin, position, linkPrefix }) => {
  const [segment, setSegment] = React.useState(-2);
  const [isProviderShowing, showProvider] = React.useState<boolean>(false);
  const useOnce = useOncePerPage();
  const pathname = usePathname();
  const [linkState, setLinkState] = React.useState({ text: 'Loading...', link: '', shouldOpenTab: false, seller: '', cta: '' });

  log.debug('release: %s, price: %o', release?.slug, price);

  if (useOnce({ item: 'price-link', skip: segment < 0 })) {
    recordAbEvent({
      event: Event.ButtonViewed,
      segment,
      testName: segmentOrigin,
      origin,
      properties: { category: 'price-link', position, release: release.slug, seller: price?.seller || '' },
    });
  }

  React.useEffect(() => {
    const onReleasePage = pathname === release.path;
    if (price) {
      let text = `From ${formatCurrency(price.purchase_price)} a pair`;
      if (price.url) {
        if (price.url.includes('ziphearing')) {
          text = `From ${formatCurrency(price.purchase_price)} a pair with local service`;
        }
        setLinkState({ text, link: price.url, shouldOpenTab: true, cta: 'From {price} a pair with local service', seller: price.seller || '' });
      } else {
        if (onReleasePage) {
          setLinkState({ text, link: '', shouldOpenTab: false, cta: 'From {price} a pair', seller: price.seller || '' });
        } else {
          // Not using this anywhere else, but may some day
          setLinkState({
            text,
            link: normalizeUrl({ url: release.path, origin: 'price-button' }),
            shouldOpenTab: false,
            cta: 'From {price} a pair',
            seller: price.seller,
          });
        }
      }
    } else {
      if (onReleasePage) {
        setLinkState({ text: 'Price not available', link: '', shouldOpenTab: false, cta: '', seller: '' });
      } else {
        // Not using this anywhere else, but may some day
        setLinkState({ text: 'Product Info', link: release.path, shouldOpenTab: false, cta: 'Product Info', seller: '' });
      }
    }
  }, [price, pathname, release.path]);

  React.useEffect(() => {
    const { segment: seg } = getAb(segmentOrigin);
    setSegment(seg);
  }, []);

  const handleSearch = () => {
    showProvider(true);
    if (onModalOpenChange) {
      onModalOpenChange(true);
    }
  };

  const onClose = () => {
    showProvider(false);
    if (onModalOpenChange) {
      onModalOpenChange(false);
    }
  };

  if ('one'.length === 0) {
    const prefix = 'From ',
      suffix = ' a pair';
    if (price?.provider_search) {
      // display popup
      return (
        <>
          <ProviderFlow
            release={release}
            model={model}
            hearingAid={hearingAid}
            onClose={onClose}
            isModalOpen={isProviderShowing}
            origin={origin}
            segment={segment}
            segmentOrigin={segmentOrigin}
            position={position}
          />
          <Button.LinkButton className="!justify-normal !gap-[0.2rem] !p-0" onClick={handleSearch}>
            <span key="prefix">{linkPrefix || prefix}</span>
            <Currency key="currency" price={price.purchase_price} fallback={'N/A'} />
            <span key="suffix">{suffix} with local service</span>
          </Button.LinkButton>
        </>
      );
    }
  }

  if (linkState.link) {
    return (
      <Link
        variant="primary"
        className="!justify-normal !gap-[0.2rem] !p-0"
        href={linkState.link}
        shouldOpenNewTab={linkState.shouldOpenTab}
        data-track={!!linkState.cta}
        data-event-name="Link"
        data-event-position="product-top-link"
        data-event-product={release.slug}
        data-event-cta="from {price} a pair with local service"
        data-event-seller={linkState.seller}
        data-event-url={linkState.link}
      >
        {linkPrefix ? (
          <>
            {linkPrefix}
            <Currency key="currency" price={price?.purchase_price} fallback={'N/A'} />
          </>
        ) : (
          linkState.text
        )}
      </Link>
    );
  }

  return <span className="!justify-normal !gap-[0.2rem] !p-0">{linkState.text}</span>;
};

export default PriceLink;
