import { useMemo } from 'react';

import { storyblokEditable } from '@storyblok/react';
import ReactMarkdown from 'react-markdown';

import { cx, titleHn } from 'lib/utils';

import styles from './faq.module.scss';

import { Accordion } from './faq-accordion/accordion';

const FAQ = ({ blok }) => {
  const accordionData = useMemo(
    () =>
      blok?.questions?.map(({ question, answer }) => ({
        header: question,
        content: <ReactMarkdown>{answer}</ReactMarkdown>,
      })),
    [blok?.questions]
  );

  if (!blok?.questions) return null;

  if (!accordionData?.length) return null;

  return (
    <section {...storyblokEditable(blok)} className={cx(styles['ht-faq'], 'ht-faq')}>
      {titleHn({ title: blok.title, heading: blok.heading })}
      <Accordion data={accordionData} />
    </section>
  );
};

export default FAQ;
