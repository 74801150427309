import React from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import fonts from 'components/layout/fonts';
import PriceButton from 'components/widgets/price-button';
import { useApiData } from 'hooks';
import logger from 'lib/logger';
import { tw } from 'lib/utils';
import IconCircleCheck from 'styles/icons-component/sharp-solid/circle-check.svg';
import type { Release, Tag } from 'types/release';

import styles from './product-scorecard.module.scss';

import { ProductFeatures } from './product-features';

const log = logger({ category: 'n4/ProductScorecard' });

interface ProductScorecardProps {
  release: Release;
  className: ClassNameValue;
}

interface IdealScenariosProps {
  tags: string[];
  className?: ClassNameValue;
  titleClassName?: string;
}

export const IdealScenarios: React.FC<IdealScenariosProps> = ({ tags, className }) => {
  const api = useApiData();
  const { tags: allTags } = api;
  const includedTags = tags.filter((t) => allTags.some((tag: Tag) => tag.tag === t && !tag.configuration.exclude_on_product));
  if (!includedTags.length) {
    return null;
  }
  return (
    <section className={tw('', className)}>
      <div className={tw('text-xl font-[300] tracking-tight text-navy')}>Ideal for</div>
      <ul className="mt-5 flex flex-col gap-3">
        {includedTags.map((tag) => (
          <li key={tag} className="flex items-center gap-3">
            <IconCircleCheck width="24" height="24" fill="#179F2D" />
            <span className="text-base leading-[140%] tracking-tight text-navy">{tag}</span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export const ProductScorecard: React.FC<ProductScorecardProps> = ({ release, className }) => {
  const model = release.models.find((m) => m.primary) || release.models[0];
  log.debug('release: %o', release);

  return (
    <section className={tw(styles['product-scorecard'], fonts.hankenGrotesk.className, className)}>
      {release.score && (
        <section className={styles.top}>
          {release.score && (
            <CircleScoreFluid
              size="lg"
              progress={(release.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
              amount={release.score}
              className="h-[100px] shadow-[1px_2px_10px_0_rgba(0,0,0,0.15)]"
              origin="bloks/n4/product/product-scorecard"
            />
          )}
          <div className={styles['top-right']}>
            <div className={styles['top-right-label']}>HearingTracker Score</div>
            {release.tier && (
              <div className={styles['top-right-title']}>
                {release.full_name} is in the {release.tier} of all products tested
              </div>
            )}
            <div className={styles['top-right-link']}>
              <a className="underlined" href="#expert-review">
                View Scoring breakdown
              </a>
            </div>
          </div>
        </section>
      )}
      {release.tags?.length ? <IdealScenarios tags={release.tags} className="h750:border-b h750:border-gray h750:pb-7" /> : null}
      <ProductFeatures keyFeatures={model.key_features} />
      <PriceButton release={release} price={release.price} noGeo origin="product/scorecard" position="product-scorecard" segmentOrigin="button" />
    </section>
  );
};
