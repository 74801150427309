// Probably needs to be deleted
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';
import { cx } from 'lib/utils';

// Can share from container if there are not many styles, otherwise use separate module per component
import styles from './home-container.module.scss';

const log = logger({ category: 'n4/home/hearing-aid-tab' });

const HearingAidTab = ({ blok, story, ...props }) => {
  log.debug('blok: %o', blok);
  return (
    <section className={cx(styles['hearing-aid-tab'], blok.className)} {...storyblokEditable(blok)} style={{ border: '1px solid gray', margin: 40 }}>
      <h3>Hearing Aid Tab/Category</h3>
      <ul>
        <li>category: {blok.category}</li>
      </ul>
      {blok.hearing_aids.length
        ? blok.hearing_aids.map((c) => <StoryblokComponent blok={c} story={story} {...props} key={c._uid} {...storyblokEditable(c)} />)
        : null}
    </section>
  );
};

export default HearingAidTab;
