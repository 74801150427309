/* eslint-disable max-len */
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { Tab, Transition, Listbox } from '@headlessui/react';
import ReactMarkdown from 'react-markdown';
import { ClassNameValue } from 'tailwind-merge';

import { RatingPopUp } from 'components/bloks/n4/product/rating-pop-up';
import ImageWrapper from 'components/common-n4/image';
import { ImageSource } from 'components/common-n4/image/types';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import fonts from 'components/layout/fonts';
import Rating from 'components/widgets/rating';
import { useIsMobileView } from 'hooks/use_is_mobile_view';
import IconAvatar from 'images/icon-avatar.svg';
import IconChevronDownSm from 'images/icon-chevron-down-sm.svg';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import { cx, tw, formatMonthYearDate } from 'lib/utils';
import type { Level, Model, Review } from 'types/release';

import { SectionTitle } from './section';
const log = logger({ category: 'n4/CustomerReviews' });

export const CustomerReviewsContext = React.createContext<{
  models: Model[];
  levels: Level[];
  image?: ImageSource | undefined;
  releaseName?: string;
}>({
  models: [],
  levels: [],
});

interface ReviewCardProps {
  review: Review;
  className: ClassNameValue;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ review, className }) => {
  const isMobileView = useIsMobileView();
  const shouldClampText = useMemo(() => review.description.length > (isMobileView ? 360 : 480), [isMobileView, review.description.length]);
  const [isShowMore, setIsShowMore] = useState<boolean>(!shouldClampText);

  return (
    <div className={tw('w-full pb-[48px] pt-[24px] text-navy laptop:pb-12 laptop:pt-6', className)}>
      <div className="flex items-end justify-between laptop:items-start">
        <div className="flex items-center gap-5">
          {review.avatar ? (
            <ImageWrapper
              key={review.id}
              image={review.avatar}
              origin="CustomerReviews"
              imgProps={{ className: 'bg-white rounded-full w-[48px] h-[48px] object-contain' }}
            />
          ) : (
            <IconAvatar className="h-[48px] w-[48px]" />
          )}
          <div className="">
            <div className="text-[18px] font-light -tracking-[0.54px] laptop:text-lg">{review.user === 'anonymous' ? 'Anonymous' : review.user}</div>
            <div className="text-[16px] font-light -tracking-[0.48px] text-navy/60 laptop:text-base laptop:font-normal">
              {formatMonthYearDate(review.created_at)}
            </div>
          </div>
        </div>
        <Rating scoreable={{ score: review.score }} useSvgStars starProps={{ width: 20, height: 20 }} />
      </div>
      <div
        className={cx(
          'mt-[32px] text-[20px] font-light leading-[140%] -tracking-[0.6px] opacity-80 laptop:mt-8 laptop:text-[18px] laptop:text-lg laptop:-tracking-[0.54px]',
          !isShowMore && (isMobileView ? 'line-clamp-4' : 'line-clamp-6')
        )}
      >
        <ReactMarkdown>{review.description}</ReactMarkdown>
      </div>
      {shouldClampText && (
        <button
          className="text-[20px] -tracking-[0.6px] text-lapis underline laptop:text-[18px] laptop:-tracking-[0.54px] laptop:no-underline"
          onClick={() => setIsShowMore((prevIsShowMore) => !prevIsShowMore)}
        >
          Show {isShowMore ? 'less' : 'more'}
        </button>
      )}
    </div>
  );
};

interface SubmitReviewParams {
  selectedModel: Model | null;
  selectedLevel: Level | null;
  reviewMessage: string;
  userScore?: number;
}

interface RatingFormProps {
  className: ClassNameValue;
  onSubmit: (result: SubmitReviewParams) => Promise<boolean>;
}

const RatingForm: React.FC<RatingFormProps> = ({ className, onSubmit: onSubmitProp }) => {
  const [userScore, setUserScore] = useState(0);
  const [isRatingPopupOpen, setIsRatingPopupOpen] = useState(false);

  const onModalClose = useCallback(() => {
    setIsRatingPopupOpen(false);
  }, []);

  const onStarClick = useCallback((score: number) => {
    setUserScore(score);
  }, []);

  const onSubmit = useCallback(
    async (result: SubmitReviewParams): Promise<boolean> => {
      const success = await onSubmitProp({ ...result, userScore });
      return success;
    },
    [onSubmitProp, userScore]
  );

  return (
    <>
      <div className={tw('rounded-[20px] border border-navy/20 p-7', className)}>
        <div className="text-[14px] -tracking-[0.42px] text-navy/80 laptop:text-sm">Have your say!</div>
        <div className="mt-1 text-xl text-navy">
          <span className="hidden laptop:block">Rate this product</span>
          <span className="text-[20px] -tracking-[0.6px] laptop:hidden">What would you rate this product?</span>
        </div>
        <Rating
          scoreable={{ score: 10 }}
          className="mt-5 hidden laptop:flex"
          canSetRating
          useSvgStars
          starProps={{ width: 32, height: 32 }}
          userScore={userScore}
          onStarClick={onStarClick}
        />
        <Rating
          scoreable={{ score: 10 }}
          className="mt-4 laptop:hidden"
          canSetRating
          useSvgStars
          starProps={{ width: 42, height: 42 }}
          userScore={userScore}
          onStarClick={onStarClick}
        />
        <div className="mt-8">
          <button
            disabled={userScore === 0}
            onClick={() => {
              setIsRatingPopupOpen(true);
            }}
            className={tw(
              'w-full rounded-full border border-navy/20 bg-lapis px-6 py-[10px] text-[16px] font-light text-white laptop:w-auto laptop:text-base laptop:font-normal',
              userScore === 0 && 'bg-transparent text-navy/40'
            )}
          >
            Submit
          </button>
        </div>
      </div>
      <RatingPopUp isOpen={isRatingPopupOpen} onClose={onModalClose} onSubmit={onSubmit} />
    </>
  );
};

interface CustomerReviewsTabPanelProps {
  reviews: Review[];
  isShowing: boolean;
  totalReviews: number;
  loading: boolean;
  onViewMoreButtonClick: () => void;
  onSubmit: (result: SubmitReviewParams) => Promise<boolean>;
}

const CustomerReviewsTabPanel: React.FC<CustomerReviewsTabPanelProps> = ({ reviews, isShowing, totalReviews, loading, onViewMoreButtonClick, onSubmit }) => {
  return (
    <>
      <Transition
        as={Fragment}
        show={isShowing}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 rotate-0 scale-100"
        leaveTo="opacity-0 scale-95 "
      >
        <div className="justify-between laptop:flex">
          <div className="">
            {reviews?.map((review, index) => <ReviewCard key={review.id} review={review} className={cx(index === 0 && 'pt-0')} />)}
            <ViewMoreButton
              className="mb-[48px] laptop:mb-0"
              isViewMore={false}
              onViewMoreButtonClick={onViewMoreButtonClick}
              negativeStateText="Read more reviews"
              positiveStateText="Read fewer reviews"
              hidden={(reviews?.length || 0) >= totalReviews || loading}
            />
          </div>
          <RatingForm className="laptop:hidden" onSubmit={onSubmit} />
        </div>
      </Transition>
    </>
  );
};

interface ModelsListboxProps {
  models: Model[];
  selectedIndex: number;
  modelIndex: number;
  onChange: (index: number) => void;
}

const ModelsListbox: React.FC<ModelsListboxProps> = ({ models, onChange, selectedIndex, modelIndex }) => {
  return (
    <Listbox as="div" className="relative inline-block w-full text-left laptop:w-[250px]" onChange={onChange}>
      <div>
        <Listbox.Button className="relative flex w-full items-center justify-between rounded-full border border-black/10 px-5 py-3 text-left">
          <span className="block truncate text-[16px] font-light -tracking-[0.48px] text-navy laptop:text-base laptop:font-normal">
            {selectedIndex === 0 ? 'All models' : models[modelIndex].name}
          </span>
          <IconChevronDownSm />
        </Listbox.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Tab.List as={Fragment}>
          <Listbox.Options className="absolute right-0 z-10 w-full origin-top-right divide-y divide-neutral-300 rounded-xl border border-neutral-300 laptop:mt-[2px] laptop:w-[250px]">
            <Listbox.Option value={0}>
              {({ active }) => (
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={tw(
                        'w-full rounded-t-xl px-4 py-[9px] text-left text-[16px] lg:text-base',
                        active || selected ? 'bg-lapis text-white' : 'bg-cream text-navy'
                      )}
                    >
                      <span>All models</span>
                    </button>
                  )}
                </Tab>
              )}
            </Listbox.Option>
            {models.map((model, index) => (
              <Listbox.Option key={model.id} value={index + 1}>
                {({ active }) => (
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={tw(
                          'w-full px-4 py-[9px] text-left text-[16px] laptop:text-base',
                          index === models.length - 1 && 'rounded-b-xl',
                          active || selected ? 'bg-lapis text-white' : 'bg-cream text-navy'
                        )}
                      >
                        {model.name}
                      </button>
                    )}
                  </Tab>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Tab.List>
      </Transition>
    </Listbox>
  );
};

interface CustomerReviewsProps {
  models: Model[];
  levels: Level[];
  releaseName: string;
  releaseSlug: string;
  image: ImageSource;
  userScore: number;
}

/*
interface SubmitReviewApiParams {
  path: string;
  method: 'POST';
  variables: { [key: string]: string | number | undefined };
};
*/

export const CustomerReviews: React.FC<CustomerReviewsProps> = ({ models, levels, releaseName, releaseSlug, image, userScore }) => {
  log.debug('models: %o', models);
  const [isShowing, setIsShowing] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [reviews, setReviews] = useState<Review[]>([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOffset(0);
    setLoading(true);
    setTotalReviews(0);
    setReviews([]);
    setSelectedIndex(0);
  }, [releaseSlug]);

  const contextValues = React.useMemo(
    () => ({
      models,
      levels,
      image,
      releaseName,
    }),
    [models, levels, image, releaseName]
  );

  const submitReview = useCallback(
    async (result: SubmitReviewParams): Promise<boolean> => {
      const { selectedModel: model, selectedLevel: level, reviewMessage: review, userScore: score } = result;
      try {
        const response = await fetchApi({
          path: `releases/${releaseSlug}/review`,
          method: 'POST',
          origin: 'CustomerReviews',
          variables: { model: model?.id, level: level?.id, review, score },
          fallback: null,
        });
        log.debug(response);
        if (response.error) {
          log.error(response.error);
          return false;
        }
        return true;
      } catch (ex: unknown) {
        log.error(ex);
        return false;
      }
    },
    [releaseSlug]
  );

  const onTabClick = useCallback(() => {
    setIsShowing(false);
    setTimeout(() => setIsShowing(true), 100);
    setReviews([]);
    setOffset(0);
    setTotalReviews(0);
  }, []);

  const onViewMoreButtonClick = () => {
    setOffset((prevOffset) => prevOffset + 3);
  };

  const modelId = selectedIndex > 0 ? models[selectedIndex - 1].id : null;

  useEffect(() => {
    setLoading(true);
    const fn = async () => {
      const data = await fetchApi({
        path: `releases/${releaseSlug}/reviews`,
        variables: {
          offset,
          limit: 3,
          model_id: modelId,
        },
        fallback: {},
        origin: 'bloks/products/n4/customer-reviews',
      });
      const newReviews = data.reviews || [];
      setReviews((previous) => {
        if (offset === 0) {
          return newReviews;
        }
        if (modelId) {
          return [...previous.filter((p) => p.model_id === modelId), ...newReviews];
        }
        return [...previous, ...newReviews];
      });
      setTotalReviews(data.filtered_count);
      setLoading(false);
    };
    fn();
  }, [offset, releaseSlug, selectedIndex, modelId]);

  return (
    <CustomerReviewsContext.Provider value={contextValues}>
      <Tab.Group
        as="div"
        className={cx(fonts.hankenGrotesk.className, 'xs:pb-[40px] md:pb-[80px]')}
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
        id="reviews"
      >
        <div className="justify-between gap-12 laptop:flex">
          <div className="mb-[64px] laptop:mb-0">
            <SectionTitle className="pb-[48px] laptop:pb-12">{releaseName} Customers Reviews</SectionTitle>
            <div className="rounded-[20px] bg-white px-[32px] py-[25px] shadow-sm laptop:w-[508px] laptop:p-10">
              <p className="hidden text-xl -tracking-[0.54px] text-navy/80 laptop:block">Customer Rating</p>
              <div className="flex items-end justify-between laptop:mt-3">
                <div className="">
                  <div className="text-[60px] font-medium leading-[112.5%] -tracking-[1.78px] text-navy laptop:text-[108px] laptop:leading-[100%] laptop:-tracking-[3.24px]">
                    {userScore.toFixed(1)}
                  </div>
                  <Rating scoreable={{ score: userScore }} className="mt-4 hidden laptop:flex" useSvgStars starProps={{ width: 32, height: 32 }} />
                  <Rating className="laptop:hidden" scoreable={{ score: userScore }} useSvgStars starProps={{ width: 20, height: 20 }} />
                </div>
                <div className="text-xl -tracking-[0.54px] text-navy/80">{!loading && totalReviews} Reviews</div>
              </div>
            </div>
            <RatingForm className="mt-6 hidden w-2/3 laptop:block" onSubmit={submitReview} />
          </div>
          <Tab.Panels as="section" className="basis-[606px]">
            {models.length > 1 && userScore > 0 && (
              <div className="items-center justify-end gap-4 pb-[64px] laptop:flex laptop:pb-12">
                <h2 className="mb-[5px] text-[18px] font-light -tracking-[0.54px] laptop:mb-0 laptop:text-base laptop:font-normal">See reviews for:</h2>
                <ModelsListbox
                  models={models}
                  selectedIndex={selectedIndex}
                  modelIndex={selectedIndex - 1}
                  onChange={(index: number) => {
                    if (index === selectedIndex) {
                      return;
                    }
                    setSelectedIndex(index);
                    onTabClick();
                  }}
                />
              </div>
            )}
            <Tab.Panel className=" ">
              <CustomerReviewsTabPanel
                reviews={reviews}
                isShowing={isShowing}
                totalReviews={totalReviews}
                onViewMoreButtonClick={onViewMoreButtonClick}
                loading={loading}
                onSubmit={submitReview}
              />
            </Tab.Panel>
            {models.map((model) => (
              <Tab.Panel key={model.id} className=" ">
                <CustomerReviewsTabPanel
                  reviews={reviews}
                  isShowing={isShowing}
                  totalReviews={totalReviews}
                  onViewMoreButtonClick={onViewMoreButtonClick}
                  loading={loading}
                  onSubmit={submitReview}
                />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </CustomerReviewsContext.Provider>
  );
};
