import { Disclosure } from '@headlessui/react';
import { ClassNameValue } from 'tailwind-merge';

import IconArrowAbove from 'images/icon-arrow-above.svg';
import IconArrowBelow from 'images/icon-arrow-below.svg';
import IconBar from 'images/icon-bar.svg';
import IconChevronUp from 'images/icon-chevron-up.svg';
import { tw } from 'lib/utils';
import { Score } from 'types/release';

import { SUB_SCORE_KEYS } from './use-sub-scores';

interface ComparisonTagProps {
  diff?: number;
  className?: ClassNameValue;
}
const ComparisonTag: React.FC<ComparisonTagProps> = ({ diff = 0, className }) => {
  let text, icon;
  if (diff > 0) {
    text = 'Above average';
    icon = <IconArrowAbove />;
  } else if (diff < 0) {
    text = 'Below average';
    icon = <IconArrowBelow />;
  } else {
    text = 'Average';
    icon = <IconBar />;
  }
  return (
    <span className={tw('inline-flex items-center gap-[4px] rounded-full bg-navy-5 px-[10px] py-[2px] lg:gap-1 lg:px-2.5 lg:py-1', className)}>
      {icon}
      <span className="w-max text-[12px] lg:text-xs">{text}</span>
    </span>
  );
};

interface SubScoreProps {
  subScoreKey: (typeof SUB_SCORE_KEYS)[number] | undefined;
  subScore?: Score | null;
  className?: ClassNameValue;
  commentClassName?: ClassNameValue;
}

export const SubScore: React.FC<SubScoreProps> = ({ subScoreKey, subScore, className, commentClassName }) => (
  <Disclosure as="li" key={subScoreKey} className={tw('rounded-xl border border-navy-10 shadow-xs', className)}>
    {({ open }) => (
      <>
        <Disclosure.Button className="flex w-full items-center justify-between px-[20px] py-[16px] text-navy lg:px-5 lg:py-[19px]">
          <span className="text-left text-[15px] text-xl -tracking-[0.54px] lg:text-lg">{subScoreKey}</span>
          <div className="flex items-center">
            <ComparisonTag diff={subScore?.comparison} className="hidden xs:inline-flex" />
            <span className="ml-3 mr-4 text-[15px] font-medium -tracking-[0.6px] lg:text-xl">{subScore?.score.toFixed(1)}</span>
            <IconChevronUp className={open ? 'rotate-180' : ''} />
          </div>
        </Disclosure.Button>
        <Disclosure.Panel className="px-5">
          <div className="border-t border-neutral-200 pb-[20px] pt-[12px] lg:pb-5 lg:pt-4">
            <div className={tw('text-lg font-light leading-[130%] -tracking-[0.3px] text-navy-90', commentClassName)}>{subScore?.comment}</div>
          </div>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);
