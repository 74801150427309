import React, { useState } from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { SUB_SCORE_KEYS, SubScore, useSubScore } from 'components/bloks/n4/product/expert-review';
import { ProductFeatures } from 'components/bloks/n4/product/product-scorecard/product-features';
import { IdealScenarios } from 'components/bloks/n4/product/product-scorecard/product-scorecard';
import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import ImageWrapper from 'components/common-n4/image';
import { Modal } from 'components/common-n4/pop-up-modal/pop-up-modal';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import { Audio } from 'components/page/directory/release_card/audio';
import Price from 'components/widgets/price';
import PriceButton from 'components/widgets/price-button';
import { useApiData } from 'hooks';
import { tw } from 'lib/utils';
import type { Model, Release } from 'types/release';

import styles from './product-info.module.scss';

interface SubScoreProps {
  subScoreKey: (typeof SUB_SCORE_KEYS)[number] | undefined;
  score: number;
  comment?: string;
  summary?: string;
  comparison: number;
}

interface ScoreCardProps {
  subScoresWithScores: (SubScoreProps | null)[];
}

interface ProductInfoPopupProps {
  className?: {
    trigger?: ClassNameValue;
  };
  release: Release;
  model: Model;
  audio: React.JSX.Element | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  useModel: boolean;
}

const ScoreCard: React.FC<ScoreCardProps> = ({ subScoresWithScores }) => {
  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  return (
    <div className="border-t-[1px] border-[#D4D4D4]">
      <p className="py-5 text-xl font-[300] tracking-tight text-navy">Expert Review</p>
      <ul className="flex flex-col gap-4">
        {subScoresWithScores
          .filter((subScore): subScore is SubScoreProps => subScore !== null)
          .map((subScore: SubScoreProps, index: number) => (
            <SubScore
              key={subScore?.subScoreKey}
              subScoreKey={subScore?.subScoreKey}
              subScore={subScore}
              className={!isViewMore && index > 3 && 'hidden lg:block'}
            />
          ))}
      </ul>
      <ViewMoreButton
        isViewMore={isViewMore}
        negativeStateText="View more scores"
        positiveStateText="View less scores"
        onViewMoreButtonClick={() => setIsViewMore((currentIsViewMore) => !currentIsViewMore)}
        className="mt-8 lg:hidden"
      />
    </div>
  );
};

export const ProductInfoPopup: React.FC<ProductInfoPopupProps> = ({ className, model, release, audio, isOpen, setIsOpen, useModel }) => {
  const scores = model.scores || release.scores;
  const price = model.price || release.price;
  const subScoresWithScores = useSubScore(scores);
  const [childModalOpen, setChildModalOpen] = useState(false);

  // This is to hide the product info popup when the provider modal is open
  const onOpenProvider = (isOpening: boolean) => {
    setChildModalOpen(isOpening);
  };

  return (
    <Modal
      origin={`product-info/${release.slug}`}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      classes={{
        overlay: childModalOpen ? 'bg-opacity-0' : 'bg-navy/[0.80] lg:bg-navy/[0.80] md:bg-navy/[0.80] sm:bg-navy/[0.80] bg-navy/[0.80]',
        panel: childModalOpen ? 'hidden' : 'md:rounded-[20px] rounded-t-[20px]',
      }}
    >
      <div
        className={tw(
          'grid h-auto w-[100vw] justify-between md:flex',
          'gap-y-[16px] p-[22px] sm:h-[587px] sm:p-[32px] md:w-[720px] md:rounded-[20px] lg:h-[587px] lg:w-[810px]',
          className
        )}
      >
        <div
          className={tw(
            'md:normal grid w-full flex-col items-start border-[#D4D4D4] md:border-b-[1px]',
            'relative pb-[0.5rem] md:flex md:w-[200px] md:border-none md:pb-[24px] lg:w-[261px]'
          )}
        >
          {scores?.total_score && (
            <CircleScoreFluid
              size="md"
              progress={((scores?.total_score || 0) / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
              amount={scores?.total_score || 0}
              className="absolute"
              origin="directory/product-info/product-info-popup"
            />
          )}
          <ImageWrapper
            image={(useModel && model.image) || release.image}
            origin="featured-articles"
            imgProps={{
              className: 'lg:p-[20px] p-[20px] pb-0',
            }}
          />
        </div>
        <div className={tw('grid gap-y-[32px] overflow-y-scroll pb-[24px] md:w-[400px] md:pt-[0.5rem] lg:w-[440px]', styles['ht-product-info-scroll'])}>
          <div className="grid">
            <span className="text-[32px] font-medium leading-[130%] tracking-[-0.32px] text-navy">{useModel ? model.full_name : release.full_name}</span>
            <span className="mt-[6px] text-[20.8px] font-medium leading-[200%] tracking-[-0.624px] text-[#4A7FE5]">
              <Price
                prefix="From "
                suffix=" a pair"
                releaseSlug={release.slug}
                modelId={model.id}
                loading="Loading price..."
                price={release.price}
                noGeo
                origin="directory/product-info-popup"
              />
            </span>
            <span className={tw('mt-[12px] text-[1.2rem] font-[400] leading-[1.5] tracking-[-0.1px] text-navy/[0.80] md:text-[1.05rem]', 'md:leading-[140%]')}>
              {release.description}
            </span>
            {audio && (
              <div className="mt-[16px] flex items-center gap-x-[15px]">
                <div>{audio}</div>
                <span className="text-[16px] font-medium leading-[140%] tracking-[-0.1px] text-navy">Listen to this device</span>
              </div>
            )}
          </div>
          <PriceButton
            release={release}
            model={model}
            price={price}
            onModalOpenChange={onOpenProvider}
            noGeo
            origin="directory/product-info-popup"
            position="product-info-popup"
            segmentOrigin="button"
          />
          {(useModel && model.tags.length) || (!useModel && release.tags?.length) ? (
            <IdealScenarios tags={useModel ? model.tags : release.tags} className="" titleClassName="tracking-tight text-navy text-lg" />
          ) : null}
          <ProductFeatures keyFeatures={model.key_features} className="border-t-[1px] border-[#D4D4D4] pb-0 pt-5" />
          {subScoresWithScores.length ? <ScoreCard subScoresWithScores={subScoresWithScores} /> : null}
        </div>
      </div>
    </Modal>
  );
};

interface ProductInfoPopupViaApiProps {
  releaseId: number;
  modelId?: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const ProductInfoPopupViaApi: React.FC<ProductInfoPopupViaApiProps> = ({ releaseId, isOpen, setIsOpen, modelId }) => {
  const [release, setRelease] = React.useState<Release | undefined>();
  const { releases } = useApiData();

  React.useEffect(() => {
    if (releaseId === release?.id) {
      return;
    }
    const chosen = releases.find((r: Release) => r.id === releaseId);
    setRelease(chosen);
  }, [releases, release, releaseId]);

  if (!release) {
    return null;
  }

  const model = (modelId && release.models.find((m) => m.id === modelId)) || release.models.find((m) => m.primary) || releases.models[0];
  const audio = release.audio ? <Audio audio={release.audio} /> : null;

  return <ProductInfoPopup release={release} model={model} audio={audio} isOpen={isOpen} setIsOpen={setIsOpen} useModel={!!modelId} />;
};
