import * as React from 'react';

import { Button } from 'components/common-n4/button';
import { Link } from 'components/common-n4/link';
import USFlag from 'images/us-flag.svg';
import { tw, validatePhoneNumber } from 'lib/utils';

import styles from '../shared.module.scss';

import { ContentProps, ErrorCode, Step, SystemAction, UserAction } from '../types';

interface HTMLInputElementWithVK extends HTMLInputElement {
  virtualKeyboardPolicy: 'auto' | 'manual' | '';
}

export const Partner: React.FC<ContentProps> = ({ dispatch, state }) => {
  const phoneInputRef = React.useRef<HTMLInputElementWithVK>(null);

  React.useEffect(() => {
    if ('virtualKeyboard' in navigator) {
      phoneInputRef.current!.virtualKeyboardPolicy = 'manual';
    }
    phoneInputRef.current?.focus();
    if ('virtualKeyboard' in navigator) {
      phoneInputRef.current!.virtualKeyboardPolicy = 'auto';
    }
  }, []);

  const handleSubmit = React.useCallback(() => {
    // validate phone number
    if (!state.phone) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.user_error_no_phone, message: 'Please provide your phone number', field: 'phone' } },
      });
    } else if (!validatePhoneNumber(state.phone)) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.user_error_bad_phone, message: 'Plesae enter a valid phone number', field: 'phone' } },
      });
    } else {
      dispatch({ type: UserAction.submit });
    }
  }, [dispatch, state.phone]);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmit]);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const cursorPosition = event.target.selectionStart ?? 0;

    // Remove all non-digit characters
    const rawPhone = input.replace(/\D/g, '');

    // Format the phone number
    let formattedNumber = '';
    if (rawPhone.length > 0) {
      formattedNumber += `(${rawPhone.slice(0, 3)}`;
      if (rawPhone.length > 3) {
        formattedNumber += `) ${rawPhone.slice(3, 6)}`;
      }
      if (rawPhone.length > 6) {
        formattedNumber += `-${rawPhone.slice(6, 10)}`;
      }
    }

    // Calculate the new cursor position
    const formatCharsBefore = (pos: number) => {
      if (pos <= 3) return 1; // Account for opening parenthesis
      if (pos <= 6) return 3; // Account for closing parenthesis and space
      return 4; // Account for hyphen
    };

    const rawCursorPosition = input.slice(0, cursorPosition).replace(/\D/g, '').length;
    const newCursorPosition = rawCursorPosition + formatCharsBefore(rawCursorPosition);

    // Update the input value
    dispatch({ type: UserAction.change, payload: { phone: formattedNumber } });

    // Set the cursor position
    setTimeout(() => {
      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleInputKeyDown = (event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <section className={tw('flex w-full items-center', styles['pop-up'], styles['found-pop-up'])}>
      <figure className={styles['pop-up-image']} />
      <div className={tw('flex w-full items-center md:items-start', styles['pop-up-content'])}>
        <div className={tw(styles['pop-up-logo-container'])}>
          <div className={tw('flex w-full items-center justify-center', styles['pop-up-logo'])} />
        </div>
        <div className={tw('flex items-center justify-center', styles['pop-up-title'])}>
          <span className={'text-center font-semibold text-navy'}>We found a provider offering this deal near you.</span>
        </div>

        {/* TODO: AB TEST We found a provider offering this deal near you! */}

        <div className={tw('mb-[10px] mt-[1rem] text-center text-base font-normal text-navy', styles['pop-up-sub-title'])}>
          Receive a no-hassle phone call to discuss the details of this offer and learn more about your zero-risk trial.
        </div>
        {state.error && (
          <div className="mb-[10px] ml-[18px] mt-[20px] text-sm" style={{ color: '#c00' }}>
            {state.error.message}
          </div>
        )}

        <div className={tw('relative mt-[10px] flex items-center', styles.phone)}>
          <input
            type="text"
            id="phone-input"
            value={state.phone}
            onChange={handlePhoneChange}
            onKeyDown={handleInputKeyDown}
            className={tw('remove-arrow w-full', state.error && state.error.field === 'phone' ? styles.error : '')}
            required
            placeholder="(xxx) xxx-xxxx"
            ref={phoneInputRef}
          />
          <span className={tw('absolute inset-y-0 start-0 flex items-center pl-[16px]', styles['input-prefix'])}>+1</span>
          <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center pr-[16px]">
            <USFlag />
          </div>
        </div>
        <Button.Primary
          className={tw('md:max-w-ful mt-[21px] flex max-w-[390px] flex-col items-center justify-center md:mt-[18px]', styles['pop-up-content-button'])}
          onClick={handleSubmit}
          buttonType="submit"
          isDisabled={state.step === Step.submitting}
        >
          Get my provider info
        </Button.Primary>
        <div className={styles['pop-up-content-terms']}>
          By clicking ‘Get my provider info’, I consent to receive communication via phone and text from HearingTracker&apos;s trusted discount partner. This
          may include contact through automated dialing systems using prerecorded messages. I understand that consent is not a condition of purchase and that I
          can opt out at any time.
          <br />
          <div className={styles.link}>
            <Link variant="primary" href={'/privacy'}>
              Privacy Policy
            </Link>
            &nbsp;|&nbsp;
            <Link variant="primary" href={'/terms'}>
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
