import * as React from 'react';

import { Button } from 'components/common-n4/button';
import { tw, validateEmail } from 'lib/utils';

import styles from './subscribe.module.scss';

import { ContentProps, ErrorCode, Step, SystemAction, UserAction } from '../types';

export const Subscribe: React.FC<ContentProps> = ({ dispatch, state }) => {
  const emailInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    emailInputRef.current?.focus();
  }, []);

  const handleSubmit = () => {
    if (!state.email) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.subscribe_error_no_email, message: 'Please provide your email', field: 'email' } },
      });
    } else if (!validateEmail(state.email)) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.subscribe_error_bad_email, message: 'Please provide a valid email such as jane.doe@gmail.com', field: 'email' } },
      });
    } else {
      dispatch({ type: UserAction.submit });
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch({ type: UserAction.change, payload: { email: value } });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <section className={tw('flex w-full items-center', styles['pop-up'], styles['found-pop-up'])}>
      <figure className={styles['pop-up-image']} />
      <div className={tw('flex w-full items-center md:items-start', styles['pop-up-content'])}>
        <div className={tw(styles['pop-up-logo-container'])}>
          <div className={tw('flex w-full items-center justify-center', styles['pop-up-logo'])} />
        </div>
        <div className={tw('flex items-center justify-center', styles['pop-up-title'])}>
          <span className={'text-center font-semibold text-navy'}>Sorry, we couldn&apos;t find a nearby provider offering this product.</span>
        </div>

        <div className={tw('text-center text-base font-normal text-navy', styles['pop-up-sub-title'])}>
          Enter your email to be notified about discounted hearing aids available in <span>{state.postalCode}</span>.
        </div>

        {state.error && (
          <div className="mb-[0px] ml-[18px] mt-[20px] text-sm" style={{ color: '#c00' }}>
            {state.error.message}
          </div>
        )}

        <div className={tw('relative flex items-center justify-center', styles.email)}>
          <input
            type="email"
            id="email"
            placeholder="Your Email address"
            value={state.email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
            className={tw('remove-arrow w-full', state.error && state.error.field === 'email' ? styles.error : '')}
            required
            ref={emailInputRef}
          />
        </div>
        <Button.Primary
          className={tw('md:max-w-ful mt-[21px] flex max-w-[390px] flex-col items-center justify-center md:mt-[24px]', styles['pop-up-content-button'])}
          buttonType="button"
          onClick={handleSubmit}
          isDisabled={state.step === Step.subscribing}
        >
          Submit
        </Button.Primary>
        <div className={tw('mb-8 mt-[80px] text-navy-90 md:mb-[34px] md:mt-[97px]', styles['change-postal-code'])}>
          Not your ZIP Code?
          <span className={tw('cursor-pointer text-lapis')} onClick={() => dispatch({ type: UserAction.restart, payload: { postalCode: '' } })}>
            {' '}
            Change
          </span>
        </div>
      </div>
    </section>
  );
};
