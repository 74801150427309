import { HearingAid, Model, Price, Release } from 'types/release';

export enum SearchResponseType {
  partner = 'partner',
  fallback = 'fallback',
  subscribe = 'subscribe',
}

export enum Step {
  start = 'start',
  searching = 'searching',
  partner = 'partner',
  submitting = 'submitting',
  submitted = 'submitted',
  fallback = 'fallback',
  subscribe = 'subscribe',
  subscribing = 'subscribing',
  subscribed = 'subscribed',
}

export enum Event {
  ButtonViewed = 'ButtonViewed',
  ButtonClicked = 'ButtonClicked',
  ZipCodeSubmitted = 'ZipCodeSubmitted',
  ZipHearingAvailable = 'ZipHearingAvailable',
  PhoneSubmitted = 'PhoneSubmitted',
  LeadAccepted = 'LeadAccepted',
  Error = 'Error',
  Subscribe = 'Subscribe',
  Restart = 'Restart',
  Fallback = 'Fallback',
  NoProviders = 'NoProviders',
  SubscribeSuccess = 'SubscribeSuccess',
}

export enum UserAction {
  restart = 'restart',
  change = 'change',
  submit = 'submit',
}

export enum SystemAction {
  set_segment = 'set_segment',
  error = 'error',
  search_response = 'search_response',
  submit_response = 'submit_response',
  subscribe_response = 'subscribe_response',
}

export enum ErrorCode {
  user_error_no_zip = 'user_error_no_zip',
  user_error_bad_zip = 'user_error_bad_zip',
  user_error_no_phone = 'user_error_no_phone',
  user_error_bad_phone = 'user_error_bad_phone',
  user_error_no_email = 'user_error_no_email',
  user_error_bad_email = 'suser_error_bad_email',
  search_error_system = 'search_error_system',
  search_error_zip = 'search_error_zip',
  submit_error_phone = 'submit_error_phone',
  submit_error_system = 'submit_error_system',
  subscribe_error_system = 'subscribe_error_system',
  subscribe_error_no_email = 'subscribe_error_no_email',
  subscribe_error_bad_email = 'subscribe_error_bad_email',
}

export interface Error {
  message: string;
  field?: string;
  code: ErrorCode;
}

export interface SearchResponse {
  message?: string;
  caption?: string;
  disclaimer?: string;
  buttonText?: string;
  providersFound?: number;
  error?: string;
  type: SearchResponseType;
  provider?: string;
  location?: string;
  trustPilotLink?: string;
  trustPilotText?: string;
  purchaseLink?: string;
}

export interface SubmitResponse {
  status: number;
  message: string;
  buttonText?: string;
  caption?: string;
  disclaimer?: string;
  leadId: number;
  error?: string;
  provider?: string;
  trustPilotLink?: string;
  trustPilotText?: string;
}

export interface SubscribeResponse {
  message: string;
  buttonText?: string;
  caption?: string;
  error?: string;
}

export type Distance = 25 | 50 | 75 | 100 | 150;

export interface StateUpdate {
  postalCode?: string;
  distance?: Distance;
  phone?: string;
  email?: string;
  searchResponse?: SearchResponse;
  submitResponse?: SubmitResponse;
  subscribeResponse?: SubscribeResponse;
  error?: Error;
  segment?: number;
  segmentOrigin?: 'button' | 'link';
  position?: string;
}

export interface State extends StateUpdate {
  step?: Step;
  release: Release;
  model?: Model;
  hearingAid?: HearingAid;
  price: Price;
  segment: number;
  segmentOrigin: 'button' | 'link';
  position: string;
  origin: string;
}

export interface Action {
  type: UserAction | SystemAction;
  payload?: StateUpdate;
}

export interface ContentProps {
  dispatch: React.Dispatch<Action>;
  state: State;
  handleClose?: () => boolean;
}
