import * as React from 'react';

import { Button } from 'components/common-n4/button';
import { tw } from 'lib/utils';

import styles from './subscribed.module.scss';

import { ContentProps } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Subscribed: React.FC<ContentProps> = ({ dispatch, state, handleClose }) => {
  const response = state.subscribeResponse!;
  return (
    <section className={tw('flex w-full items-center', styles['pop-up'], styles['found-pop-up'])}>
      <div className={tw('flex w-full items-center md:items-start', styles['pop-up-content'])}>
        <section className={tw('flex w-full', styles['pop-up-final'])}>
          <div className={tw('flex w-full items-center', styles['pop-up-final-content'])}>
            <div className={tw(styles['pop-up-logo-container'])}>
              <div className={tw('flex w-full items-center justify-center', styles['pop-up-logo'])} />
            </div>
            <div className={tw('flex items-center justify-center', styles['pop-up-title'])}>
              <span className={'text-center font-semibold text-navy'}>{response.message}</span>
            </div>

            <div className={tw('text-center text-base font-normal text-navy', styles['pop-up-sub-title'])}>{response.caption}</div>

            <Button.Primary
              className={tw(
                'mt-[21px] flex max-w-[390px] flex-col items-center justify-center md:mt-[18px] md:max-w-full',
                styles['pop-up-content-button'],
                styles['provider-info-button']
              )}
              buttonType="submit"
              onClick={handleClose}
            >
              {response.buttonText || 'OK'}
            </Button.Primary>
          </div>
        </section>
      </div>
    </section>
  );
};
