import React, { useState, useContext, useEffect } from 'react';

import { motion } from 'framer-motion';
import { omit } from 'lodash';
import { ClassNameValue } from 'tailwind-merge';

import ImageWrapper from 'components/common-n4/image';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import Price from 'components/widgets/price';
import PriceButton from 'components/widgets/price-button';
import IconArrowLeftNavy from 'images/icon-arrow-left-navy.svg';
import { tw } from 'lib/utils';
import type { LevelFeatureDetail, ModelFeatureDetail } from 'types/release';

import { Feature } from './feature';
import { ModelComparisonContext } from './model-comparison';
import { getFeatureNameForFeature } from './utils';

const ITEMS_PER_PAGE = 4;
const MODEL_COLUMN_WIDTH = 264;
const MODEL_GAP = 32;
const SCROLL_X_UNIT = MODEL_COLUMN_WIDTH + MODEL_GAP;

interface RowProps extends React.PropsWithChildren {
  className?: ClassNameValue;
}

const Row: React.FC<RowProps> = ({ children, className }) => <div className={tw('flex gap-3 lg:gap-0', className)}>{children}</div>;

interface ColumnProps extends React.PropsWithChildren {
  className?: ClassNameValue;
}

const Column: React.FC<ColumnProps> = ({ children, className }) => (
  <div className={tw('w-[48.5%] shrink-0 lg:w-[172px] laptop:w-[228px] desktop:w-[264px]', className)}>{children}</div>
);

interface ModelFeatureRowProps {
  id: number; // software_feature_id, i.e. proprietary
  name: string;
  featureName?: string;
  value?: string;
  className?: ClassNameValue;
  type: string;
  featureDetails: ModelFeatureDetail[] | LevelFeatureDetail[];
}

const ModelFeatureRow: React.FC<ModelFeatureRowProps> = ({ id, featureDetails, className, type }) => {
  const { models, brandName, level } = useContext(ModelComparisonContext);
  const featureDetail = featureDetails.find((f) => f.id === id);
  if (!featureDetail) {
    console.warn('featuredetail missing: %o', { id, featureDetails });
    return null;
  }
  const modelDetails = models.map((m) => ({
    id: m.id,
    proprietaryName: featureDetail.proprietary_name,
    genericName: featureDetail.generic_name,
    proprietaryDescription: featureDetail.proprietary_description,
    genericDescription: featureDetail.generic_description || featureDetail.description,
    disclaimer: featureDetail.disclaimer,
    value: 'N/A',
  }));
  if (type === 'hardware') {
    modelDetails.forEach((mv) => {
      const model = models.find((m) => m.id === mv.id);
      const matchingFeature = model!.features.find((f) => f.id === id);
      if (matchingFeature && !(matchingFeature.excluded_technology_level_ids || []).includes(level!.id)) {
        mv.value = matchingFeature.value || '✓';
      }
    });
  } else {
    const swFeature = level!.features.find((f) => f.id === id);
    if (swFeature) {
      modelDetails.forEach((m) => {
        if (!(swFeature.excluded_model_ids || []).includes(m.id)) {
          m.value = swFeature.value || '✓';
        }
      });
    }
  }

  if (!modelDetails.some((m) => m.value !== 'N/A')) {
    return null;
  }
  return (
    <Row className={className}>
      {modelDetails.map((detail, index) => {
        return (
          <Column key={detail.id} className={tw('lg:mx-4', index === 0 && 'lg:ml-12')}>
            <Feature
              type={type}
              titleClassName={index > 0 ? 'lg:invisible' : undefined}
              brandName={brandName}
              popoverButtonClassName={index > 0 ? 'lg:hidden' : undefined}
              {...omit(detail, 'id')}
            />
          </Column>
        );
      })}
    </Row>
  );
};

const ModelHeaderRow = () => {
  const { release, models, level } = useContext(ModelComparisonContext);

  return (
    <Row>
      {models?.map((model, index) => {
        const hearingAid = (release?.hearing_aids || []).find((ha) => ha.modelId === model.id && ha.levelId === level!.id);
        return (
          <Column key={model.id} className={tw('lg:mx-4', index === 0 && 'lg:ml-12')}>
            <ImageWrapper
              image={model.image}
              imgProps={{ className: 'p-8 border border-navy/20 rounded-[20px] object-contain' }}
              origin="model-comparison-multi-products-tab"
            />
            <div className="mt-[28px] lg:mt-6">
              <h3 className="text-[20px] font-medium leading-[120%] -tracking-[0.6px] text-navy lg:text-xl">{model.full_name}</h3>
            </div>
            <span
              className="flex min-h-[22px] items-start gap-2 text-[12px] -tracking-[0.36px] text-navy/60 
            lg:text-lg lg:leading-[120%] lg:-tracking-[0.54px]"
            >
              <Price
                releaseSlug={model.release_slug}
                modelId={model.id}
                levelId={level?.id}
                hearingAidId={hearingAid?.id}
                loading="Loading..."
                suffix=" a pair"
                price={level?.price || model?.price || release?.price}
                noGeo
                origin="product/model-comparison-multi"
              />
            </span>
            <PriceButton
              release={release!}
              model={model}
              hearingAid={hearingAid}
              price={hearingAid?.price || level?.price || model.price || release?.price}
              noGeo
              className="mt-4 !w-full justify-center text-white lg:mt-6 lg:p-0"
              origin="product/model-comparison-multi"
              position="product-model-comparison"
              segmentOrigin="button"
            />
          </Column>
        );
      })}
    </Row>
  );
};

interface FeaturePanelProps {
  title?: string;
  featureDetails?: ModelFeatureDetail[] | LevelFeatureDetail[];
  className?: ClassNameValue;
  isViewMore?: boolean;
  type: string;
  destinationX: number;
}

const FeaturePanel: React.FC<FeaturePanelProps> = ({ title, featureDetails, type, destinationX }) => {
  return (
    <>
      <div className="sticky left-0 mt-[14px] lg:mt-10 lg:px-12">
        <h4 className="border-b border-neutral-300 py-5 text-[20px] font-medium leading-[120%] -tracking-[0.6px] text-navy lg:text-xl">{title}</h4>
      </div>
      <motion.div className="flex flex-col lg:mt-8 lg:gap-8" animate={{ translateX: destinationX }} transition={{ ease: 'easeOut' }}>
        {featureDetails?.map((feature) => {
          const featureName = getFeatureNameForFeature(feature);
          return (
            <ModelFeatureRow
              key={feature.id}
              id={feature.id}
              name={featureName || ''}
              type={type}
              featureDetails={featureDetails}
              featureName={feature.name || feature.generic_name || ''}
            />
          );
        })}
      </motion.div>
    </>
  );
};

const isLastPage = (firstItemOfCurrentPage: number, totalCount: number) => {
  return firstItemOfCurrentPage + ITEMS_PER_PAGE >= totalCount;
};

interface MultiProductsTabPanelProps {
  selectedIndex: number;
}

export const MultiProductsTabPanel: React.FC<MultiProductsTabPanelProps> = ({ selectedIndex }) => {
  const [firstItemOfCurrentPage, setFirstItemOfCurrentPage] = useState<number>(0);
  const [destinationX, setDestinationX] = useState<number>(0);
  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  const { models, modelFeatureDetails, levelFeatureDetails } = useContext(ModelComparisonContext);
  const totalCount = models?.length || 0;

  useEffect(() => {
    setFirstItemOfCurrentPage(0);
  }, [selectedIndex]);

  useEffect(() => {
    setDestinationX(-(firstItemOfCurrentPage * SCROLL_X_UNIT));
  }, [firstItemOfCurrentPage]);

  return (
    <div className="relative">
      <section className="overflow-auto lg:flex-row lg:gap-8 lg:overflow-hidden">
        <motion.div className="flex flex-col gap-8" animate={{ translateX: destinationX }} transition={{ ease: 'easeOut' }}>
          <ModelHeaderRow />
        </motion.div>
        <FeaturePanel featureDetails={modelFeatureDetails} title="Hardware" type="hardware" destinationX={destinationX} />
        {isViewMore && <FeaturePanel featureDetails={levelFeatureDetails} title="Software" type="software" destinationX={destinationX} />}
        <ViewMoreButton
          isViewMore={isViewMore}
          onViewMoreButtonClick={() => setIsViewMore((currentIsViewMore) => !currentIsViewMore)}
          className="sticky left-0 mt-8 lg:mt-16"
          negativeStateText="Show all features"
          positiveStateText="Show fewer features"
          // hidden={(features?.length || 0) <= FEATURES_TO_SHOW_INITIALLY}
        />
      </section>
      {firstItemOfCurrentPage !== 0 && (
        <button
          className="absolute left-[21px] top-[107px] hidden h-[54px] w-[54px] items-center justify-center rounded-full border border-navy bg-white lg:flex"
          onClick={() => {
            setFirstItemOfCurrentPage((prevVal) => (prevVal - ITEMS_PER_PAGE < 0 ? 0 : prevVal - ITEMS_PER_PAGE));
          }}
        >
          <IconArrowLeftNavy />
        </button>
      )}
      {!isLastPage(firstItemOfCurrentPage, totalCount) && (
        <button
          className="absolute right-[21px] top-[107px] hidden h-[54px] w-[54px] items-center justify-center rounded-full border border-navy bg-white lg:flex"
          onClick={() => {
            setFirstItemOfCurrentPage((prevVal) =>
              prevVal + ITEMS_PER_PAGE * 2 >= totalCount ? prevVal + (totalCount - (prevVal + ITEMS_PER_PAGE)) : prevVal + ITEMS_PER_PAGE
            );
          }}
        >
          <IconArrowLeftNavy className="rotate-180" />
        </button>
      )}
    </div>
  );
};
