import logger from './logger';
import { track } from './tracking';

const log = logger({ category: 'ab-test' });

const tests: Record<string, string[]> = {
  buttonPartner: ['Find local savings on {release}', 'Save on {release}', 'Buy {release}'],
  buttonAffiliate: ['Purchase {release}', 'Save on {release}', 'Buy {release}'],
  mobileButtonPartner: [
    'Buy Now',
    'Save Now',
    'Get Offer',
    'Find Savings',
    'Shop Now',
    'Claim Offer',
    'Save Big',
    'View Deal',
    'Unlock Savings',
    'Grab Deal',
    'Redeem Offer',
    'Check Price',
  ],
  mobileButtonAffiliate: [
    'Buy Now',
    'Save Now',
    'Get Offer',
    'Find Savings',
    'Shop Now',
    'Claim Offer',
    'Save Big',
    'View Deal',
    'Unlock Savings',
    'Grab Deal',
    'Redeem Offer',
    'Check Price',
  ],
  link: ['with local service'],
};

export interface RecordAbProps {
  testName: string;
  event: string;
  segment: number;
  origin: string;
  properties?: Record<string, string>;
}

export const recordAbEvent = ({ event, segment, testName, origin, properties = {} }: RecordAbProps) => {
  log.debug('ab: %s/%d/%s', event, segment, testName);
  if (tests[testName] && segment >= 0 && segment < tests[testName].length) {
    track({
      event,
      eventType: 'abtest',
      origin,
      properties: { ...properties, cta: tests[testName][segment] },
    });
  }
};

export const getAb = (testName: string, substitutions: Record<string, string> = {}) => {
  let segment = -99;
  if (typeof window === 'undefined' || typeof window.localStorage === 'undefined' || !tests[testName]) {
    // eslint-disable-next-line no-console
    log.debug('No ab test for %s', testName);
    return { segment, cta: '', text: '' };
  }
  const ctas = tests[testName];
  const itemName = `ht-ab-${testName}`;
  if (localStorage.getItem(itemName)) {
    segment = parseInt(localStorage.getItem(itemName)!, 10);
  }
  if (typeof segment === 'undefined' || segment === null || segment < 0 || segment > ctas.length - 1) {
    segment = Math.floor(Math.random() * ctas.length);
    window.localStorage.setItem(itemName, segment.toString());
  }
  let text = ctas[segment];
  Object.entries(substitutions).forEach(([from, to]) => (text = text.replace(`{${from}}`, to)));
  // eslint-disable-next-line no-console
  log.debug('ab test: %s -> %o', testName, { segment, text, cta: ctas[segment] });
  return { segment, text, cta: ctas[segment] };
};
